/*
 * Smooth Scroll
 */
(function() {
  // Si l'url comprend une ancre alors on va tenter de scroller vers celle-ci
  var anchor= null;
  anchor = window.location.hash;
  anchor = anchor.substring(1,anchor.length); // enleve le #
  //Si il y a une ancre dans l'url
  if ( anchor!= '' && document.getElementById(anchor) !== null && document.getElementById(anchor) !== undefined) {
      setTimeout(function(){
          scrollMe("#"+anchor); //On demande a scroller vers celle-ci
      }, 50);
  }

  // Click sur un element
  elSmoothScroll = Array.prototype.slice.call( document.querySelectorAll( '.js-smooth-scroll' ) );

  if (sectionsParallax !== undefined) {
    var elTop;  
    elSmoothScroll.forEach( function( el, i ) {
      el.addEventListener('click', function (event) {
        // event.preventDefault();
        attribut = this.getAttribute("href");

        var page = this.getAttribute('href'); // Page cible
        if (page.match('#')) {
            var pageAnchor = page.split('#')[1];
            var pageNoAnchor = page.split('#')[0];
        }
        else{
            // return false;
            var pageAnchor = this.dataset.anchor;
        }
        elTop = getOffset(document.getElementById(pageAnchor)).top;
        if (DEBUG) console.log('elSmoothScroll ' + elTop);
        scrollToY(elTop, 1500, 'easeInOutQuint');
        // return true;
      }, false);

    });
  }

  // Va lancer le smooth scroll vers l'ancre demandé
  function scrollMe(page){
    if (DEBUG) console.log('scrollMe to '+page);
    var speed = 500; // DurÃ©e de l'animation (en ms)
    var elCibleScroll;
    
    elCibleScroll=document.querySelector(page);
    var elTop = getOffset(elCibleScroll).top;
    // console.log(elTop);
    // elTop=elTop-100;
    scrollToY(elTop, 1500, 'easeInOutQuint');
    return true;
  }

  // Main function
  function scrollToY(scrollTargetY, speed, easing) {
      //On va ajuster la valeur a atteindre car le header est fixed
      var headerEl = document.querySelector("header.header.is-fixed");
      if (headerEl !== null && headerEl !== undefined){
        var headerH = headerEl.clientHeight
        scrollTargetY = scrollTargetY - headerH;
      }
      
      // scrollTargetY: the target scrollY property of the window
      // speed: time in pixels per second
      // easing: easing equation to use
      var scrollY = window.scrollY || document.documentElement.scrollTop,
          scrollTargetY = scrollTargetY || 0,
          speed = speed || 2000,
          easing = easing || 'easeOutSine',
          currentTime = 0;

      // min time .1, max time .8 seconds
      var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

      // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
      var easingEquations = {
              easeOutSine: function (pos) {
                  return Math.sin(pos * (Math.PI / 2));
              },
              easeInOutSine: function (pos) {
                  return (-0.5 * (Math.cos(Math.PI * pos) - 1));
              },
              easeInOutQuint: function (pos) {
                  if ((pos /= 0.5) < 1) {
                      return 0.5 * Math.pow(pos, 5);
                  }
                  return 0.5 * (Math.pow((pos - 2), 5) + 2);
              }
          };

      // add animation loop
      function tick() {
          currentTime += 1 / 60;

          var p = currentTime / time;
          var t = easingEquations[easing](p);

          if (p < 1) {
              window.requestAnimationFrame(tick);

              window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
          } else {
              if (DEBUG) console.log('scroll done');
              window.scrollTo(0, scrollTargetY);
          }
      }

      // call it once to get started
      tick();
  }
  // scroll it!
  // scrollToY(0, 1500, 'easeInOutQuint');
})();