(function() {});

DEBUG= false; // Passer à false pour stoper les console.log() de débug.

/* va calculer les dimensions de la fenetre (largeur/hauteur/ratioPixel)__:
 * les variables seront accessibles par les autres fct
--------------------------------------------------------------------------------*/
var DOC_ELEM = document.documentElement;
var W_WIDTH, W_HEIGHT, W_PIXEL_RATIO, H_DOC, W_DOC, SCROLL_Y; //Variables globales
function dimensionsFenetre(){
  W_WIDTH= DOC_ELEM.clientWidth || window.innerWidth 
  W_HEIGHT= DOC_ELEM.clientHeight || window.innerHeight 
  W_PIXEL_RATIO = window.devicePixelRatio;
  H_DOC = document.body.clientHeight;//Calcule la hauteur de la page entière
  W_DOC = document.body.clientWidth;//Calcule la hauteur de la page entière
  SCROLL_Y = window.pageYOffset || DOC_ELEM.scrollTop; //Indique en pixel de combien est le scroll
  if (DEBUG) console.log('Fenetre dimensions : '+W_WIDTH+'px, '+'W_HEIGHT : '+W_HEIGHT+', pixelratio : '+W_PIXEL_RATIO+'. Hauteur totale doc : '+H_DOC+', largeur totale doc : '+W_DOC+', Scroll :'+SCROLL_Y+', ');
  return true;
}

/* Appeler au scrool
* cette fonction est appelée a chaque resize
* elle sert elle a appeller les fcts qui ont besoin detre mise a jour au resize
------------------------------------------------------------------------------------ */
function scrollHandler(){
  SCROLL_Y = window.pageYOffset || DOC_ELEM.scrollTop; //on met a jour la variable globale du scrool
   if (DEBUG) console.log(SCROLL_Y + " pixel de scroll");
    //>Ci dessous mettre toutes les fct qui doivent etre appelées au scrool
    requestAnimationFrame(function() {
      parallaxActionsElements();
      apparitionElements();
      animationProgressActionsElements();
      apparitionPages();
    });
}

  // http://stackoverflow.com/a/5598797/989439
  function getOffset( el ) {
    var offsetTop = 0, offsetLeft = 0;
    do {
      if ( !isNaN( el.offsetTop ) ) {
        offsetTop += el.offsetTop;
      }
      if ( !isNaN( el.offsetLeft ) ) {
        offsetLeft += el.offsetLeft;
      }
    } while( el = el.offsetParent )

    return {
      top : offsetTop,
      left : offsetLeft
    }
  }

  //Permet de determiner si un elemetn est dans le viewport
  function inViewport( el, h ) {
    var elH = el.offsetHeight,
      scrolled = SCROLL_Y,
      viewed = scrolled + W_HEIGHT,
      elTop = getOffset(el).top,
      elBottom = elTop + elH,
      // if 0, the element is considered in the viewport as soon as it enters.
      // if 1, the element is considered in the viewport only when it's fully inside
      // value in percentage (1 >= h >= 0)
      h = h || 0;

    return (elTop + elH * h) <= viewed && (elBottom - elH* h) >= scrolled;
  }

 /* 
 *
 * INITS GENERAUX__:
 *
 */

//>Calcul de la taille disponible (de la fenetre), Cette fct utilise des variables globales
  dimensionsFenetre(); //ON a aussi besoin d'appeler cette fct au resize
  //on rapelle la fct plus tard si des elements n'ont pas eu le temps de charger (images,...)
  setTimeout(function(){
    dimensionsFenetre();
  }, 1200);

//>Va a chaque resize appeler une fct
(function() {
  var the_timer;
  window.addEventListener( "orientationchange", function( event ) {
    //Le fait d'utiliser en timer evite de consommer trop de ressource quand on s'amuse a redimensionner la fenetre
    //Ca fluidifi et ca evite quelques bugs quand on relache la fenetre
    clearTimeout(the_timer);
    the_timer = setTimeout(function(){
      dimensionsFenetre();
    }, 75);
  });
})();


//>Va a chaque resize appeler une fct
(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle("resize", "optimizedResize");
})();

// handle event
window.addEventListener("optimizedResize", function() {
    dimensionsFenetre();
});

//>Va a chaque scroll appeler une fct.
var ticking = false;
window.addEventListener('scroll', function() {
  if (!ticking) {
    window.requestAnimationFrame(function() {
      scrollHandler();
      ticking = false;
    });
  }
  ticking = true;
});


/*
 *  Animation progress horizontale
 */
if ( W_DOC >= '767' && document.querySelector('.js-section-inviewport-progress') !=undefined && document.querySelector('.js-section-inviewport-progress') !=null  ){
  animationProgress_init();
}
else{}

var sectionsAnimationProgress, viewportFactorSP;
function animationProgress_init(){

  sectionsAnimationProgress = Array.prototype.slice.call( document.querySelectorAll( '.js-section-inviewport-progress' ) );
  // The viewportFactor defines how much of the appearing item has to be visible in order to trigger the animation
  // if we'd use a value of 0, this would mean that it would add the animation class as soon as the item is in the viewport. 
  // If we were to use the value of 1, the animation would only be triggered when we see all of the item in the viewport (100% of it)
  viewportFactorSP = 0;
  animationProgressActionsElements();
}

function animationProgressActionsElements() {
  if (sectionsAnimationProgress== undefined) return;
  var elTop, calcul;  
  sectionsAnimationProgress.forEach( function( el, i ) {
    if( inViewport( el, viewportFactorSP ) ) {
      elTop = getOffset(el).top;
      calcul = -( elTop - (W_HEIGHT/2) - SCROLL_Y) / 8;
      // console.log('parallaxActionsElements calcul ' + calcul);
      calcul=Math.round(calcul);

      if (el.classList.contains("is-playing")){
        video.play();
      } else{
        // video.pause();
      }

      el.querySelector('.js-progress-h').style.transform = "translateX(" + calcul +"px)";
      if (el.querySelector('.js-progress-h.js-progress-h--negative') !== null){
        el.querySelector('.js-progress-h.js-progress-h--negative').style.transform = "translateX(" + (calcul*-1) +"px)";
      }
      
    }
    else {
      // this add class init if it doesn't have it. This will ensure that the items initially in the viewport will also animate on scroll
    }
  });
}


/*
 *Apparition des élements dans le viewportFactor
 */
if ( document.querySelector('.js-section-inviewport-display') !=undefined && document.querySelector('.js-section-inviewport-display') !=null  ){
  apparitionElements_init();
}
else{}
var sectionsInViewportDisplay, viewportFactorSID;
function apparitionElements_init(){

  sectionsInViewportDisplay = Array.prototype.slice.call( document.querySelectorAll( '.js-section-inviewport-display' ) );
  // The viewportFactor defines how much of the appearing item has to be visible in order to trigger the animation
  // if we'd use a value of 0, this would mean that it would add the animation class as soon as the item is in the viewport. 
  // If we were to use the value of 1, the animation would only be triggered when we see all of the item in the viewport (100% of it)
  viewportFactorSID = 0.3;
  apparitionElements();
}

function apparitionElements() {
  if (sectionsInViewportDisplay== undefined) return;
  var dataReplay = true;
  sectionsInViewportDisplay.forEach( function( el, i ) {
    if( inViewport( el, viewportFactorSID ) ) {
      el.classList.add('is-in-viewport'); 
    }
    else {
      // this add class init if it doesn't have it. This will ensure that the items initially in the viewport will also animate on scroll
      dataReplay = el.dataset.replay // On recupere la valeur du data attribute replay (data-replay)
      // console.log(typeof(dataReplay));
      if (dataReplay != 'false'){
        el.classList.remove('is-in-viewport');
      }
    }
  });
}

 /*
  * Gestion du parallax pour les section parallax
  *
  * Image de fond qui monte ou descend plus vite que le scroll
 */
if ( document.querySelector('.parallaxsection') !=undefined && document.querySelector('.parallaxsection') !=null  ){
  parallaxSections_init();
}
else{}
var sectionsParallax, viewportFactorSP;
function parallaxSections_init(){

  sectionsParallax = Array.prototype.slice.call( document.querySelectorAll( '.parallaxsection' ) );
  // The viewportFactor defines how much of the appearing item has to be visible in order to trigger the animation
  // if we'd use a value of 0, this would mean that it would add the animation class as soon as the item is in the viewport. 
  // If we were to use the value of 1, the animation would only be triggered when we see all of the item in the viewport (100% of it)
  viewportFactorSP = 0;
  parallaxActionsElements();
}

function parallaxActionsElements() {
  if (sectionsParallax== undefined) return;
  var elTop, calcul;  
  sectionsParallax.forEach( function( el, i ) {
    if( inViewport( el, viewportFactorSP ) ) {
      elTop = getOffset(el).top;
      calcul = ( elTop - (W_HEIGHT/2) - SCROLL_Y) / 8;
      // console.log('parallaxActionsElements calcul ' + calcul);
      calcul=Math.round(calcul);
      el.querySelector('.parallaxsection-parallax').style.backgroundPosition = "50% " + calcul +"px";
    }
    else {
      // this add class init if it doesn't have it. This will ensure that the items initially in the viewport will also animate on scroll
    }
  });
}

/*
 * Video
 */
var videoWrapper = document.querySelector('.video-custom');
var video = videoWrapper.querySelector('video');
var videoTimer;
video.removeAttribute("controls");

videoWrapper.addEventListener('click', function (event) {
  // event.preventDefault();
  if (!this.classList.contains("is-playing")){
    video.play();
  } else{
    // video.pause();
  }
  return true;
}, false);

video.addEventListener('pause', function() {
  videoWrapper.classList.remove('is-playing');
  clearTimeout(videoTimer);
  videoTimer = setTimeout(function(){
    video.removeAttribute("controls");
  }, 150);

  console.log('pause');
});

video.addEventListener('play', function() {
  clearTimeout(videoTimer);
  video.setAttribute("controls", "");
  videoWrapper.classList.add('is-playing');
  console.log('play');
});

video.addEventListener('seeking', function() {
  // console.log('seeking');
});
