/*
 * Lazy Load
 * Charger les images une fois que les autres contenus sont chargé
 */
(function() {

  function loadImages() {
    //Pour les img classique
    /*var imgs = document.getElementsByTagName('img');
    for (var i = 0; i < imgs.length; i++) {
        if (imgs[i].getAttribute('data-src')) {
            imgs[i].setAttribute('src', imgs[i].getAttribute('data-src'));
        }
    }*/

    //Pour les sections parallax
    var parallaxSections = document.querySelectorAll('.parallaxsection-parallax');
    for (var i = 0; i < parallaxSections.length; i++) {
        if (parallaxSections[i].getAttribute('data-src')) {
            parallaxSections[i].style.backgroundImage='url(' + parallaxSections[i].getAttribute('data-src') + ')';
            parallaxSections[i].removeAttribute('data-src');
        }
    }
  }
   
  if (window.addEventListener) { window.addEventListener("load", loadImages, false); }
  else if (window.attachEvent) { window.attachEvent("onload", loadImages); }

})();