/*
 * Menu principal
 */

/*
 * Ouverture/fermeture menu mobile
 */
document.addEventListener('DOMContentLoaded', function () {
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
});

/*
 * 
 */
var navBarEl, navbarItems, pagesInViewportDisplay, viewportFactorPages, navbarTimer, navbarTicking;
navBarEl = document.getElementById('navbar');
// var parallaxSections = document.querySelectorAll('.parallaxsection-parallax');
$navbarItems = Array.prototype.slice.call(navBarEl.querySelectorAll('.navbar-menu a.navbar-item'), 0);

// Check if there are any navbar items
if ($navbarItems.length > 0) {

  // Add a click event on each of them
  $navbarItems.forEach(function ($el) {
    $el.addEventListener('click', function () { 
      navbarResetTicking();
      navBarActiveClass($el);
      navBarEl.querySelector('.navbar-burger').classList.remove('is-active');
      navBarEl.querySelector('.navbar-menu').classList.remove('is-active'); // Permet de refermer le menu mobile
      // $el.classList.add('is-active');
    });
  });

} else{}

// Change la classe active sur l'élément demandé
navbarTicking = true;
function navBarActiveClass(elActive) {
  if (!navbarTicking) { return false; }
  if ($navbarItems.length <= 0) { return false; }

  navbarTicking = false;

  $navbarItems.forEach(function ($el) {
    $el.classList.remove('is-active');
  });
  elActive.classList.add('is-active');

  clearTimeout(navbarTimer);
  navbarTimer = setTimeout(function(){
    navbarTicking = true;
  }, 500);
}

function navbarResetTicking() {
  clearTimeout(navbarTimer);
  navbarTicking = true;
}

/*
 *Apparition des pages dans le view port
 */
if ( document.querySelector('.js-page') !=undefined && document.querySelector('.js-page') !=null  ){
  apparitionPages_init();
}
else{}

function apparitionPages_init(){
  sectionsInViewportDisplay = Array.prototype.slice.call( document.querySelectorAll( '.js-page' ) );
  viewportFactorPages = 0.3;
  apparitionPages();
}

function apparitionPages() {
  if (sectionsInViewportDisplay== undefined) return;
  var id, itemActif;
  sectionsInViewportDisplay.forEach( function( el, i ) {
    if( inViewport( el, viewportFactorPages ) ) {

      id = el.getAttribute("id");
      itemActif = navBarEl.querySelector('.navbar-menu a.navbar-item[data-anchor="' + id + '"]');
      navBarActiveClass(itemActif);  
    }
    else {}
  });
}
