/*
 * Les sliders
 */

// Slider Home page
var sliderHome = new Swiper ('#home-swiper-container', {
  // Optional parameters
  loop: false,
  init: false,
  parallax: true,
  // If we need pagination
  pagination: {
    el: '#home-swiper-pagination',
    clickable : true,
  },

});

var homeSliderSlides;
homeSliderSlides = Array.prototype.slice.call(document.querySelectorAll('#home-swiper-container .swiper-slide'), 0);

// Apres le changement d'une slide
sliderHome.on('init', function() {
  if (homeSliderSlides.length > 0) {

    homeSliderSlides.forEach(function ($el) {
      let video = $el.querySelector('video');
      if ( video !== null && video != undefined) {
        video.volume = 0.5;
      }
    });

  } else{}
});
// init Swiper
sliderHome.init();

// Apres le changement d'une slide
sliderHome.on('slideChangeTransitionEnd', function() {
  if (DEBUG) console.log('sliderHome event : slideChangeTransitionEnd');
  if (homeSliderSlides.length > 0) {

    homeSliderSlides.forEach(function ($el) {
      //On met en pause toute les vidéos sauf celle du slider actif
      if (!$el.classList.contains("swiper-slide-active")){
        let video = $el.querySelector('video');
        if ( video !== null && video != undefined) {
          video.pause();
        }
      } else{}

    });

  } else{}

});

// Slider Projet
var sliderProjet = new Swiper ('#projet-swiper-container', {
  // Optional parameters
  loop: false,
  parallax: true,
  pagination: {
    el: '#projet-swiper-pagination',
    clickable : true,
  },

});

// Slider galerie
var sliderGalerie = new Swiper ('#galerie-swiper-container', {
  // Optional parameters
  init: false,
  loop: false,
  speed: 300,
  parallax: true,
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 105,
  slideToClickedSlide: true,
  pagination: {
    el: '#galerie-swiper-pagination',
    clickable : true,
  },
  navigation: {
    nextEl: '#galerie-swiper-button-next',
    prevEl: '#galerie-swiper-button-prev',
  },
  breakpoints: {
    1920: {
      spaceBetween: 105, //Laisse le breakpoint 1920 sinon le parallax ne fonctionne plus haut dessus de 1440 (le breakpoint precedent)
    },
    1440: {
      spaceBetween: 80,
    },
    1024: {
      spaceBetween: 50,
    },
    640: {
      spaceBetween: 15,
    }
  }
});

sliderGalerie.on('resize', function() {
  // console.log('sliderGalerie event : resize');
  sliderGalerie.update();
});
sliderGalerie.init();
